import { useState } from 'react'
import clsx from 'clsx'
// import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Form, Alert, Button } from 'react-bootstrap-v5'
import { userService } from "../../services/UserServices"
import { useIntl } from 'react-intl'

type SendLogin = {
    username: string;
    password: string;
};

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm(),
        intl = useIntl(),
        [viewPass, setViewPass] = useState<boolean>(false),
        [messageError, setMessageError] = useState<String>(''),
        [validInput, setValidInput] = useState<boolean>(false),
        [loading, setLoading] = useState<boolean>(false),
        onSubmit = async (data: SendLogin) => {
            setMessageError('');
            setValidInput(true);
            setLoading(true);
            userService.login(data).then(
                user => {
                    window.location.href = "/";
                },
                error => {
                    setValidInput(false);
                    setLoading(false);
                    if (String(error) === 'TypeError: Failed to fetch') {
                        setMessageError('El navegador no pudo establecer una conexión al servidor');
                    } else {
                        setMessageError(error);
                    }
                }
            );
        }



    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="h1 text-uppercase">
                    {intl.formatMessage({ id: 'AUTH.TITLE' })}
                </h3>
                <p className="text-muted fw-bolder">
                    {intl.formatMessage({ id: 'AUTH.SUBTITLE' })}

                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={handleSubmit((data: any) => onSubmit(data))}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >

                <div className="form-group fv-plugins-icon-container mb-10">
                    <label className="form-label">Usuario</label>
                    <input
                        placeholder={intl.formatMessage({ id: 'AUTH.INPUT.USUARIO' })}
                        type="text"
                        className={
                            clsx(
                                'form-control form-control-solid h-auto py-5 px-5',
                                {
                                    'is-valid': validInput
                                },
                                {
                                    'is-invalid': errors.username && errors.username.type === "required"
                                }
                            )
                        }
                        {...register('username',
                            {
                                required: "Este campo es requerido",
                            }
                        )
                        }
                    />
                    {errors.username && errors.username.type === "required" && (
                        <div className="fv-plugins-message-container text-danger text-center">
                            <div className="fv-help-block">
                                {errors.username.message}
                            </div>
                        </div>
                    )}
                </div>
                <div className="form-group fv-plugins-icon-container mb-5">
                    <label className="form-label">Contraseña</label>
                    <div className="input-group">
                        <input
                            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                            type={viewPass ? 'text' : 'password'}
                            className={
                                clsx(
                                    'form-control form-control-solid h-auto py-5 px-5',
                                    {
                                        'is-valid': validInput
                                    },
                                    {
                                        'is-invalid': errors.password && errors.password.type === "required"
                                    }
                                )
                            }
                            {...register("password",
                                {
                                    required: "Este campo es requerido",
                                }
                            )
                            }
                        />
                        <button className="btn btn-secondary" type="button" onClick={() => setViewPass(!viewPass)}><i className={`bi bi-eye${viewPass ? '-slash' : ''}-fill`} /></button>
                    </div>
                    {errors.password && errors.password.type === "required" && (
                        <div className="fv-plugins-message-container text-danger text-center">
                            <div className="fv-help-block">
                                {errors.password.message}
                            </div>
                        </div>
                    )}
                </div>
                {
                    messageError &&
                    <Form.Group>
                        <Alert variant="alert-dismissible bg-light-danger text-danger fw-bolder text-center">
                            {messageError}
                        </Alert>
                    </Form.Group>
                }


                <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                    {/* <Link
                        to="/auth/forgot-password"
                        className="text-black-50 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                    >
                        {intl.formatMessage({ id: 'AUTH.GENERAL.FORGOT_BUTTON' })}
                    </Link> */}
                    <Button
                        id="kt_login_signin_submit"
                        type="submit"
                        // disabled={formik.isSubmitting}
                        className={`btn btn-primary px-5 py-4 my-3`}
                    >
                        <span className="fw-bolder">{intl.formatMessage({ id: 'AUTH.GENERAL.LOGIN_BUTTON' })}</span>
                        {loading &&
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Procesando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        }
                    </Button>
                </div>
            </form >
        </div >
    )
}

export default Login