var KTLandingPage = {
    init: function() {
        // your code here
    }
};

if (typeof module !== 'undefined') {
    module.exports = KTLandingPage;
}

document.addEventListener('DOMContentLoaded', function() {
    KTLandingPage.init();
});
