import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const
  Federaciones = lazy(() => import('../../../views/Federaciones/index'))


const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}`} component={Federaciones} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
