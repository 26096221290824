/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const informacionPublica = () => {
  

  return (
      <AsideMenuItemWithSub
        to='/informacion-publica'
        title= {"Información Pública"}
        featherIcon={<i className="bi-files fs-3"></i>}
      >
        
        <AsideMenuItem
          to='/informacion-publica/admin'
          title={"Administrar Información Pública"}
          biIcon="bi bi-grid-1x2"
        />
        <AsideMenuItem
          to='/informacion-publica/solicitudes'
          title={"Solicitudes de Información Pública"}
          biIcon="bi bi-grid-1x2"
        />
      </AsideMenuItemWithSub>
  )
}

export default informacionPublica