import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const
  DiaOlimpico = lazy(() => import('../../../views/DiaOlimpico/index'))


const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}`} component={DiaOlimpico} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
