import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Rol = lazy(() => import('../../../views/IT/Rol/index')),
      Usuario = lazy(() => import('../../../views/IT/Usuario/index')),
      Departamento = lazy(() => import('../../../views/IT/Departamento/index')),
      Municipio = lazy(() => import('../../../views/IT/Municipio/index')),
      ConfigCorreo = lazy(() => import('../../../views/IT/ConfigEmail/index')),
      TipoPersona = lazy(() => import('../../../views/IT/TipoPersona/index')),
      Genero = lazy(() => import('../../../views/IT/Genero/index')),
      Grupo = lazy(() => import('../../../views/IT/Grupo/index')),
      SubGrupo = lazy(() => import('../../../views/IT/SubGrupo/index')),
      Renglon = lazy(() => import('../../../views/IT/Renglon/index'))

const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/tipo-persona`} component={TipoPersona} />
      <Route path={`${props.match.path}/rol`} component={Rol} />
      <Route path={`${props.match.path}/genero`} component={Genero} />
      <Route path={`${props.match.path}/usuario`} component={Usuario} />
      <Route path={`${props.match.path}/config-correo`} component={ConfigCorreo} />
      <Route path={`${props.match.path}/departamento`} component={Departamento} />
      <Route path={`${props.match.path}/municipio`} component={Municipio} />
      <Route path={`${props.match.path}/grupo`} component={Grupo} />
      <Route path={`${props.match.path}/sub-grupo`} component={SubGrupo} />
      <Route path={`${props.match.path}/renglon`} component={Renglon} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
