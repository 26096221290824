import { FC } from 'react'
import { ScrollTop } from './components/ScrollTop'
import { MasterInit } from './MasterInit'
import { PageDataProvider } from './core'
import { ToastContainer } from 'react-toastify'
// import Load from '../../app/utility/Loading/index'
import { LoadingProvider } from '../../app/utility/Loading/provider'

import 'react-toastify/dist/ReactToastify.css';
import '../assets/js/custom/landing.js'
// import '../assets/js/scripts.bundle.js'
// import '../assets/css/style.bundle.css'
import '../assets/global/plugins.bundle.css'

const MasterPublic: FC = ({ children }) => {

  return (
    <PageDataProvider>
      <LoadingProvider>
        {children}
        <ToastContainer />
        {/* <Load /> */}
        <MasterInit />
        <ScrollTop />
      </LoadingProvider>
    </PageDataProvider>
  )
}

export { MasterPublic }
