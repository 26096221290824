import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Empleado011 = lazy(() => import('../../../views/rrhh/personal011/index'))
const EmpleadoPorContrato = lazy(() => import('../../../views/rrhh/personalPorContrato/index'))
const TipoUbicacion = lazy(() => import('../../../views/rrhh/tipoUbicacion/index'))
const Ubicacion = lazy(() => import('../../../views/rrhh/ubicacion/index'))
const UbicacionSeccion = lazy(() => import('../../../views/rrhh/ubicacionSeccion/index'))
const Puesto = lazy(() => import('../../../views/rrhh/puesto/index'))
const Etnia = lazy(() => import('../../../views/rrhh/etnia/index'))
const EstadoCivil = lazy(() => import('../../../views/rrhh/estadoCivil/index'))
const Profesion = lazy(() => import('../../../views/rrhh/profesion/index'))
const GradoAcademico = lazy(() => import('../../../views/rrhh/gradoAcademico/index'))
const Sedes = lazy(() => import('../../../views/rrhh/Sede/index'))
const TipoSangre = lazy(() => import('../../../views/rrhh/tipoSangre/index')) 

const PuestoFuncional = lazy(() => import('../../../views/rrhh/puestoFuncional/index'))
const PuestoNominal = lazy(() => import('../../../views/rrhh/puestoNominal/index'))
const ServiciosPrestados = lazy(() => import('../../../views/rrhh/serviciosPrestados/index'))
const TipoSalarioDescuento = lazy(() => import('../../../views/rrhh/tipoSalarioDescuento/index'))


const Index = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/tipo-ubicacion`} component={TipoUbicacion} />
      <Route path={`${props.match.path}/ubicacion`} component={Ubicacion} />
      <Route path={`${props.match.path}/seccionubicacion`} component={UbicacionSeccion} />
      <Route path={`${props.match.path}/puesto`} component={Puesto} />
      <Route path={`${props.match.path}/empleado/011`} component={Empleado011} />
      <Route path={`${props.match.path}/empleado/por-contrato`} component={EmpleadoPorContrato} />
      <Route path={`${props.match.path}/etnia`} component={Etnia} />
      <Route path={`${props.match.path}/estado-civil`} component={EstadoCivil} />
      <Route path={`${props.match.path}/profesion`} component={Profesion} />
      <Route path={`${props.match.path}/grado-academico`} component={GradoAcademico} />
      <Route path={`${props.match.path}/sedes`} component={Sedes} />
      <Route path={`${props.match.path}/tipo-sangre`} component={TipoSangre} />
      <Route path={`${props.match.path}/puesto-funcional`} component={PuestoFuncional} />
      <Route path={`${props.match.path}/puesto-nominal`} component={PuestoNominal} />
      <Route path={`${props.match.path}/servicios-prestados`} component={ServiciosPrestados} />
      <Route path={`${props.match.path}/tipo-salario`} component={TipoSalarioDescuento} /> 
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
