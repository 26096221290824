/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import { useContext } from 'react'
import IT from './Menu/IT/index'
import Capacitaciones from './Menu/Capacitaciones/index'
import RRHH from './Menu/rrhh/index'
import InformacionPublica from './Menu/InformacionPublica'
import CounterAdmin from './Menu/CounterAdmin'
import { UserContext } from '../../../../app/utility/context/userContext'

export function AsideMenuMain() {
  const { Roles, userRoles } = useContext(UserContext)


  return (
    <>

      {
        userRoles.includes(Roles.adm) && <IT />
      }
      {
        userRoles.includes(Roles.adm) && <CounterAdmin />
      }
      
      {
        userRoles.includes(Roles.a_cap) && <Capacitaciones />
      }
      
      {
        userRoles.includes(Roles.a_rrhh) && <RRHH />
      }
      {
        userRoles.includes(Roles.a_public) && <InformacionPublica />
      }
      
    </>
  )
}
