import { Fragment } from 'react'
import { Link, Switch, Redirect, Route } from "react-router-dom"
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import "../../../_metronic/assets/sass/core/pages/login/login-1.scss"
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Reset from './Reset'
import { ContentProvider } from './context'

const AuthPage = () => {
    return (
        <Fragment>
            <ContentProvider>
                <div className="d-flex flex-column flex-root">
                    {/*begin::Login*/}
                    <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                        {/*begin::Aside*/}
                        <div
                            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                            style=
                            {
                                {
                                    // backgroundImage: `url(${toAbsoluteUrl("/media/stock/900x600/14.jpg")})`,
                                    background: '#071629'
                                }
                            }
                        >
                            {/*begin: Aside Container*/}
                            <div className="d-flex flex-row-fluid flex-column justify-content-between justify-content-center">
                                {/* start:: Aside header */}
                                <span className="flex-column-auto mt-5 pb-lg-0 pb-10 text-white h1">
                                    {/* <img
                                        alt="Logo"
                                        className="max-h-70px"
                                        src={toAbsoluteUrl("/media/logos/Logo.png")}
                                    /> */}
                                </span>
                                {/* end:: Aside header */}

                                {/* start:: Aside content */}
                                <div className="justify-content-center text-center">
                                    <Link to="/inicio" className="flex-column-auto mt-5 pb-lg-0 pb-10 text-white h1"><img className="img-aside" alt="login" src={toAbsoluteUrl("/media/logos/logo-3.webp")} />
                                    </Link>
                                    {/* <p className="font-weight-lighter text-white opacity-80">
                                The ultimate Bootstrap & React 16 admin theme framework for
                                next generation web apps.
                                </p> */}
                                </div>
                                {/* end:: Aside content */}

                                {/* start:: Aside footer for desktop */}
                                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                    <div className="opacity-100 font-weight-bold	text-white">
                                        &copy; {new Date().getFullYear()}
                                    </div>
                                    <div className="d-flex">
                                        {/* <Link to="/terms" className="text-white mx-5">Privacy</Link>
                                    <Link to="/terms" className="text-white mx-5">Legal</Link>
                                    <Link to="/terms" className="text-white mx-5">Contact</Link> */}
                                    </div>
                                </div>
                                {/* end:: Aside footer for desktop */}
                            </div>
                            {/*end: Aside Container*/}
                        </div>
                        {/*begin::Aside*/}

                        {/*begin::Content*/}
                        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                            {/*begin::Content header*/}
                            {/* <Row  className="top-0 right-0 mt-5 mb-15 mb-lg-0 py-5 px-10">
                            <Col>
                                <p className="text-end fw-bolder text-black-50">
                                    ¿Aún no tienes una cuenta?
                                    <Link
                                        to="/auth/registration"
                                        className=" ms-4 fs-6"
                                        id="kt_login_signup"
                                    >
                                        ¡Crea una!
                                    </Link>
                                </p>
                            </Col>
                        </Row>   */}
                            {/* <div className="top-0 right-0 mt-5 mb-15 mb-lg-0 py-5 px-10">
                            <span className="font-weight-bold text-white-50 text-end">
                                ¿Aún no tienes una cuenta?
                            </span> 
                            
                        </div> */}
                            {/*end::Content header*/}

                            {/* begin::Content body */}
                            {/* <div className="d-flex flex-column-fluid flex-center align-items-center mt-30 mt-lg-0"></div> */}
                            <div className="d-flex flex-column-fluid flex-center align-items-center mt-30 mt-lg-0">
                                <Switch>
                                    <Route path='/auth/login' component={Login} />
                                    <Route path="/auth/forgot-password" component={ForgotPassword} />
                                    <Route path='/auth/reset/password/:codigo' component={Reset} />


                                    <Redirect from="/auth" exact={true} to="/auth/login" />
                                    <Redirect to="/auth/login" />
                                </Switch>
                            </div>
                            {/*end::Content body*/}

                            {/* begin::Mobile footer */}
                            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                                <div className="text-white-50 font-weight-bold order-2 order-sm-1 my-2">
                                    &copy; {new Date().getFullYear()} CIV
                                </div>
                            </div>
                            {/* end::Mobile footer */}
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*end::Login*/}
                </div>
            </ContentProvider>
        </Fragment>
    )
}

export default AuthPage