import { useContext, useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap-v5'
// import { ModelForm } from '../../utility/models/configEmail'
import { ContentContext } from './context'
import CustomLabel from '../../utility/customLabel/index'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

export const FormUpdate = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm()
    const { oneData, StoreUpdate, setOneData, ubicaciones, DataAll } = useContext(ContentContext)

    const onSubmit = (data: any) => {
        let jsData = {
            id: (oneData) ? oneData.id : null,
            emailServerAccount: data.email,
            passwordEmailAccount: data.password,
            smtpServer: data.smtp,
            smtpPort: data.port
        }

        StoreUpdate(jsData)
        Cancel()
    }

    const setData = async () => {
        setValue('email', oneData.emailServerAccount)
        setValue('smtp', oneData.smtpServer)
        setValue('port', oneData.smtpPort)
    }

    const Cancel = () => {
        reset()
        setOneData([])
    }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (oneData) { await setData() }
            }
            fetchMyAPI()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [oneData]
    )

    return (
        <>
            { DataAll && DataAll?.data&& DataAll?.data[0]?.idEstado === 1 ?
                <Card className='mt-5 p-8'>

                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <CustomLabel required={true} text="Documento de Identificación (CUI)" type="danger" />
                                    <Form.Control
                                        type="number"
                                        autoComplete='off'
                                        {...register('dpi',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido"
                                                }

                                            }
                                        )
                                        }
                                        isInvalid={!!errors.dpi}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.dpi && errors.dpi.message}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGridAddress2">
                                    <CustomLabel required={true} text="NIT" type="danger" />
                                    <Form.Control
                                        type="number"
                                        // autoComplete='new-password'
                                        {...register('nit',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido"
                                                }
                                            }
                                        )
                                        }
                                        isInvalid={!!errors.nit}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.nit && errors.nit.message}</p>
                                </Form.Group>
                            </Col>
                        </Row>




                        <Row className="mb-3">
                            <Form.Group className="mb-3">
                                <CustomLabel required={true} text="Dirección" type="danger" />
                                <Form.Control
                                    as="textarea"
                                    {...register('direccion',
                                        {
                                            required: "Este campo es requerido",
                                        }
                                    )
                                    }
                                    // readOnly={opcion === 3}
                                    isInvalid={!!errors.direccion}
                                />
                                <p className="text-danger fw-danger">{!!errors.direccion && errors.direccion.message}</p>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={false} text="No. de Teléfono" />
                                    <Form.Control
                                    // {...register('telefono',
                                    //     {
                                    //         required: "Este campo es requerido",
                                    //     }
                                    // )
                                    // }
                                    // readOnly={opcion === 3}
                                    // isInvalid={!!errors.telefono}
                                    />
                                    {/* <p className="text-danger fw-danger">{!!errors.telefono && errors.telefono.message}</p> */}
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={true} text="Celular" type="danger" />
                                    <Form.Control
                                        {...register('telefono',
                                            {
                                                required: "Este campo es requerido",
                                            }
                                        )
                                        }
                                        // readOnly={opcion === 3}
                                        isInvalid={!!errors.telefono}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.telefono && errors.telefono.message}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={true} text="Correo Electrónico" type="danger" />
                                    <Form.Control
                                        {...register('email',
                                            {
                                                required: "Este campo es requerido",
                                            }
                                        )
                                        }
                                        // readOnly={opcion === 3}
                                        isInvalid={!!errors.email}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.email && errors.email.message}</p>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <CustomLabel required={true} text="Tipo de Sangre" type="danger" />
                                    <Form.Control
                                        type="text"
                                        autoComplete='off'
                                        {...register('tipoSangre',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido"
                                                }

                                            }
                                        )
                                        }
                                        isInvalid={!!errors.tipoSangre}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.tipoSangre && errors.tipoSangre.message}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <CustomLabel required={true} text="Número de Afiliación del IGSS" type="danger" />
                                    <Form.Control
                                        type="text"
                                        autoComplete='off'
                                        {...register('igss',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido"
                                                }

                                            }
                                        )
                                        }
                                        isInvalid={!!errors.igss}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.igss && errors.igss.message}</p>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <CustomLabel required={true} text="Profesión" type="danger" />
                                    <Form.Control
                                        type="text"
                                        autoComplete='off'
                                        {...register('profesion',
                                            {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido"
                                                }

                                            }
                                        )
                                        }
                                        isInvalid={!!errors.profesion}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.profesion && errors.profesion.message}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={false} text="No. de Colegiado" />
                                    <Form.Control
                                    // {...register('telefono',
                                    //     {
                                    //         required: "Este campo es requerido",
                                    //     }
                                    // )
                                    // }
                                    // readOnly={opcion === 3}
                                    // isInvalid={!!errors.telefono}
                                    />
                                    {/* <p className="text-danger fw-danger">{!!errors.telefono && errors.telefono.message}</p> */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={true} text="Ubicación" type="danger" />
                                    <Controller
                                        name="ubicacion"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    defaultValue={null}
                                                    options={ubicaciones}
                                                    placeholder={"Seleccione una opción"}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                />
                                            )
                                        }
                                        }
                                        rules={{ required: "Este campo es requerido" }}

                                    />
                                    <span className="text-danger" >{!!errors.ubicacion && errors.ubicacion.message}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={true} text="Fecha de Ingreso" type="danger" />
                                    <Controller
                                        control={control}
                                        name='fechaIngreso'
                                        defaultValue={new Date()}
                                        render={({ field }) => (
                                            <DatePicker
                                                // disabled={opcion === 3}
                                                autoComplete='off'
                                                className='form-control'
                                                placeholderText='Fecha Ingreso'
                                                onChange={(e) => field.onChange(e)}
                                                selected={field.value}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Seleccione una fecha',
                                            },
                                        }}
                                    />
                                    <p className="text-danger fw-danger">{!!errors.fechaIngreso && errors.fechaIngreso.message}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mb-3">
                                    <CustomLabel required={true} text="Puesto Nominal" type="danger" />
                                    <Controller
                                        name="puesto"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    isSearchable
                                                    defaultValue={null}
                                                    // isDisabled={opcion === 3}
                                                    // options={puestos}
                                                    placeholder={"Seleccione una opción"}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                />
                                            )
                                        }
                                        }
                                        rules={{ required: "Este campo es requerido" }}
                                    />
                                    <p className="text-danger border-2 fw-danger">{!!errors.puesto && errors.puesto.message}</p>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <div className="d-grid gap-2"> */}
                        {/* 
        <Button variant="secondary" size="sm" onClick={() => toggleModal(0)} data-for='cancelar' data-tip='Cancelar'>
            <Icon.ArrowLeft size={16} /> {"Actualizar Datos"}
        </Button> */}

                        {
                            // opcion !== 2 &&
                            <>
                                <Button variant="success" size="sm" type="submit" data-for='guardar' data-tip='Guardar'>
                                    <Icon.Save size={16} /> {"Actualizar Datos"}
                                </Button>

                            </>
                        }
                        {/* </div> */}
                    </Form>
                </Card>
                : null
            }
        </>

    )
}
export default FormUpdate