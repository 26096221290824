import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const 
      Noticias = lazy(() => import('../../../views/NoticiasPublic/index')),
      NoticiasParis = lazy(() => import('../../../views/NoticiasParis2024')),
      DetalleNoticias = lazy(() => import('../../../views/NoticiasPublic/DetalleMicroCapsulas')),
      DetalleNoticisParis = lazy(() => import('../../../views/NoticiasParis2024/DetalleMicroCapsulas'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/public/:code`} component={DetalleNoticias} />
      <Route path={`${props.match.path}/public`} component={Noticias} />
      <Route path={`${props.match.path}/juegos-olimpicos-paris/:code`} component={DetalleNoticisParis} />
      <Route path={`${props.match.path}/juegos-olimpicos-paris`} component={NoticiasParis} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
