const RouteBase = process.env.REACT_APP_ROUTE_BASE

export async function GetRoute(url) {
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
  })
    .then(function (data) {
      //StatusCode(data);
      return data.json()
    })
    .catch(function (data) {
      //StatusCode(data);
      return []
    })
  return await response
}

export async function GetRouteSWR(url) {
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
  }).then(function (data) {
    //StatusCode(data);
    return data.json()
  })
  return await response
}

// **************************************************************************
// Funcion guardar registros json
// **************************************************************************
export async function PostRoute(url, form) {
  const data = JSON.stringify({
    usuario: JSON.parse(localStorage.getItem('authUserI')).code,
    ...form,
  })
  // elviamos el formulario con fetch por el metodo post
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
    body: data,
  })
    .then(function (data) {
      // StatusCode(data);
      return data.json()
    })
    .catch(function (data) {
      // StatusCode(data);
      return []
    })

  return await response
}
export async function PostRouteSWR(url, form) {
  const data = JSON.stringify({
    usuario: JSON.parse(localStorage.getItem('authUserI')).code,
    ...form,
  })
  // elviamos el formulario con fetch por el metodo post
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
    body: data,
  }).then(function (data) {
    // StatusCode(data);
    return data.json()
  })

  return await response
}

// **************************
// Funcion guardar registros con documentos
// **************************
export async function PostRouteFD(url, form) {
  form.append('usuario', JSON.parse(localStorage.getItem('authUserI')).code)
  // elviamos el formulario con fetch por el metodo post
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
    body: form,
  })
    .then(function (data) {
      // StatusCode(data);
      return data.json()
    })
    .catch(function (data) {
      // StatusCode(data);
      return []
    })

  return await response
}

// **************************
// Funcion guardar registros con documentos indicando el tiempo que se tarda
// **************************
export async function PostRouteFDT(url, form) {
  form.append('usuario', JSON.parse(localStorage.getItem('authUserI')).code)

  // Obtén la hora actual antes de enviar el archivo
  const startTime = new Date().getTime()

  // elviamos el formulario con fetch por el metodo post
  const response = await fetch(`${RouteBase}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`,
    },
    body: form,
  })
    .then(function (data) {
      // StatusCode(data);
      return data.json()
    })
    .then((data) => {
      // Obtén la hora actual después de enviar el archivo
      const endTime = new Date().getTime()

      // Calcula la diferencia en segundos
      const timeTaken = (endTime - startTime) / 1000

      console.log(`Tiempo tomado para enviar el archivo: ${timeTaken} segundos`)
      return data
    })
    .catch(function (data) {
      // StatusCode(data);
      return []
    })

  return await response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GetRoute,
  PostRoute,
  PostRouteSWR,
  PostRouteFD,
  GetRouteSWR,
  PostRouteFDT
}
