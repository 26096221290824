import {USERAUTH} from '../types/user'

const initialState = {
  auth: {
    estado: 0,
    login: false,
  },
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERAUTH:
      return {
        ...state,
        auth: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
