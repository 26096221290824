/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const Capacitaciones = () => {
  

  return (
      <AsideMenuItemWithSub
        to='/noticias'
        title= {"Noticias"}
        featherIcon={<i className="fab fa-chromecast fs-3"></i>}
      >
        
        <AsideMenuItem
          to='/noticias/categoria-noticias'
          title={"Categoria Noticias"}
          biIcon="bi bi-grid-1x2"
        />
        <AsideMenuItem
          to='/noticias/admin'
          title={"Administrar Noticias"}
          biIcon="bi bi-grid-1x2"
        />
        <AsideMenuItem
          to='/noticias/url-youtube'
          title={"URL Youtube"}
          biIcon="bi bi-grid-1x2"
        />
      </AsideMenuItemWithSub>
  )
}

export default Capacitaciones