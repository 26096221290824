/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import * as Icon from 'react-feather'
const IT = () => {
  return (
    <AsideMenuItemWithSub
      to='/rrhh'
      title='Recursos Humanos'
      featherIcon={<Icon.Users size={18} />}
    >
      <AsideMenuItemWithSub
        to='/ingresoPersonal'
        title='Ingreso de Personal'
        featherIcon={<Icon.Layers size={18} />}
      >
        <AsideMenuItem to='/rrhh/empleado/011' title='011' featherIcon={<Icon.User size={18} />} />
        <AsideMenuItem
          to='/rrhh/empleado/por-contrato'
          title='Por Contrato'
          featherIcon={<Icon.User size={18} />}
        />
      </AsideMenuItemWithSub>
      
      <AsideMenuItemWithSub
        to='/catalogo'
        title='Catálogos'
        featherIcon={<Icon.Layers size={18} />}
      >
        <AsideMenuItem to='/rrhh/tipo-sangre' title='Tipo de Sangre' faIcon='fa-tint' />

        <AsideMenuItem to='/rrhh/tipo-ubicacion' title='Tipo de Dependencia' faIcon='fa-project-diagram' /> 
        
        <AsideMenuItem to='/rrhh/ubicacion' title='Dependencias' faIcon='fa-sitemap' />

        <AsideMenuItem to='/rrhh/seccionubicacion' title='Unidades Administrativas' faIcon='fa-project-diagram' />

        <AsideMenuItem to='/rrhh/puesto-funcional' title='Puesto Funcional' faIcon='bi-briefcase' />

        <AsideMenuItem to='/rrhh/puesto-nominal' title='Puesto Nominal' faIcon='bi-briefcase' />

        <AsideMenuItem to='/rrhh/servicios-prestados' title='Servicios Prestados' biIcon='bi-circle' />

        <AsideMenuItem to='/rrhh/tipo-salario' title='Tipo de Salarios y Descuentos' biIcon='bi-circle' />
        
        <AsideMenuItem to='/rrhh/salario-descuento' title='Escala Salarios y Descuentos' biIcon='bi-circle' />

        <AsideMenuItem to='/rrhh/estado-civil' title='Estado Civil' biIcon='bi-circle' />

        <AsideMenuItem to='/rrhh/etnia' title='Etnia' biIcon='bi-globe' />

        <AsideMenuItem to='/rrhh/profesion' title='Profesión' faIcon='fa-user-tie' />

        <AsideMenuItem
          to='/rrhh/grado-academico'
          title='Grado Academico'
          faIcon='fa-user-graduate'
        />

        <AsideMenuItem to='/rrhh/puesto' title='Puesto' biIcon='bi-briefcase' />
      </AsideMenuItemWithSub>

    
    </AsideMenuItemWithSub>
  )
}

export default IT
