import { FC } from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
// import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import { ToastContainer } from 'react-toastify'
// import Main from '../partials/layout/create-app/CreateApp'
import Load from '../../app/utility/Loading/index'
import { LoadingProvider } from '../../app/utility/Loading/provider'
import { useLocation } from "react-router-dom";
import { validUrl } from '../../app/utility/Globales/index'
import 'react-toastify/dist/ReactToastify.css';

const MasterLayout: FC = ({children}) => {
  const { pathname } = useLocation();

  return (
    <PageDataProvider>
      <LoadingProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className={`wrapper d-flex flex-column flex-row-fluid ${ (pathname.includes(validUrl[1]) || validUrl.includes(pathname)) ? 'pt-19' : '' } `} id='kt_wrapper'>
            {/* muestra el encabezado de cada catalogo */}
            <HeaderWrapper />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {/* muesta el nombre de cada catalogo */}
              {/* <Toolbar /> */}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  {children}
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <ToastContainer />
        <Load />
        <MasterInit />
        <ScrollTop />
      </LoadingProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
