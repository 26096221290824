import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const 
      CounterAdmin = lazy(() => import('../../../views/CounterAdmin/Counter/index'))
      // ,
      // DetalleMicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Microcapsulas/DetalleMicroCapsulas'))


const Index = (props: any) =>{
  return (
    <Switch>
      {/* <Route path={`${props.match.path}/admin/:code`} component={DetalleMicroCapsulas} /> */}
      <Route path={`${props.match.path}/admin`} component={CounterAdmin} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
