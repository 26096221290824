import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='bg-light d-flex flex-column justify-content-center text-center align-self-center h-100 w-100'>
      <div>
        <img style={{height: '250px'}}src={toAbsoluteUrl('/media/logos/logo_oficial-1.png')} alt='Start logo' />
        <br/>
        <span>Cargando...</span>
      </div>
    </div>
  )
}
