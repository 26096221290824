/* eslint-disable react-hooks/rules-of-hooks */
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { GetRoute, GetRouteSWR, PostRoute, PostRouteSWR, PostRouteFD } from '../../services/Private'
import { GetRoute as GetPublic, PostRoute as PostPublic } from '../../services/Public'


export const useRequest = (path, method, form, config) => {
    if (method === 'GET') {
        const { data, error } = useSWR(path, GetRoute, config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'GET1000') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 5000 });
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }

    if (method === 'GETSWR') {
        let { data, error } = useSWR(path, GetRouteSWR, config);
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'GETSWRPUBLIC') {
        let { data, error } = useSWR(path, GetPublic, config);
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'GETSWRPUBLIC2000') {
        let { data, error } = useSWR(path, GetPublic, { refreshInterval: 2000 });
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'getInmutable') {
        let { data, error } = useSWRImmutable(path, GetRouteSWR);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POST') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );


        const { data, error } = useSWR(isValid === true ? [path, form] : null, PostRoute, config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POSTPUBLIC') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );


        const { data, error } = useSWR(isValid === true ? [path, form] : null, PostPublic, config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POSTSWR') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );

        // console.log(isValid)
        let { data, error } = useSWR(isValid === true ? [path, form] : null, PostRouteSWR, config);
        return {
            data: data?.data ?? [],
            response: data ?? [],
            isLoading: !data?.data,
            isError: error
        }
    }
    if (method === 'postInmutable') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );


        let { data, error } = useSWRImmutable(isValid === true ? [path, form] : null, PostRouteSWR);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'postInmutablePublic') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );


        let { data, error } = useSWRImmutable(isValid === true ? [path, form] : null, PostPublic);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'FORMDATA') {
        // Comprueba si algún valor en 'form' es null o 0 
        const isValid = form && !Object.values(form).some(value => value === null || value === 0 || value === undefined || Number.isNaN(value) || value === '' ||  (Array.isArray(value) && !value.length) );


        const { data, error } = useSWR(isValid === true ? [path, form] : null, PostRouteFD(), config);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
}