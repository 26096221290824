/* eslint-disable @typescript-eslint/no-unused-vars */
import { Suspense, lazy, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { UserContext } from '../../app/utility/context/userContext'
import IT from './private/IT/index'
import Capacitaciones from './private/Capacitaciones/index'
import RRHH from './private/rrhh/index'
import InfoPublica from './private/InfoPublica/index'
import EventosAdmin from './private/CounterAdmin/index'


import Perfil from '../views/Perfil/'
// import Bienvenido from '../views/tablero/Bienvenido'


export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../views/tablero/index'))
  const { Roles, userRoles } = useContext(UserContext)
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

        {
          <Route path='/dashboard' component={BuilderPageWrapper} />
          // <Route path='/dashboard' component={Bienvenido} />
        }
        {
          userRoles.includes(Roles.adm) && <Route path='/it' component={IT} />
        }
        
        {
          userRoles.includes(Roles.adm) && <Route path='/eventos' component={EventosAdmin} />
        }
        
        {
          <Route path='/perfil' component={Perfil} />
        }

        {
          <Route path='/noticias' component={Capacitaciones} />
        }

        {
          userRoles.includes(Roles.a_public) &&  <Route path='/informacion-publica' component={InfoPublica} />
        }

        {
          userRoles.includes(Roles.a_rrhh) && <Route path='/rrhh' component={RRHH} />
        }




        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />

        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
