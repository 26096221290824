import { Fragment } from 'react'

import { ContentProvider } from './context'
import { Toolbar3 } from '../../../_metronic/layout/components/toolbar/Toolbar3'
import Formulario from './Form'
import FormUpdate from './FormUpdate'

const Perfil = () => {

    return (
        <Fragment>
            <ContentProvider>
                <Toolbar3
                    DefaultTitle={"PERFIL DE USUARIO"}
                    pageModal={() => <></>}
                />
                <Formulario />
                <FormUpdate />
            </ContentProvider>
        </Fragment>
    )
}

export default Perfil