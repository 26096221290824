import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const 
      MicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Microcapsulas')),
      DetalleMicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Microcapsulas/DetalleMicroCapsulas')),
      CategoriaMicroCapsulas = lazy(() => import('../../../views/Capacitaciones/Categoria')),
       URLYoutube = lazy(() => import('../../../views/URLYoutube'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/admin/:code`} component={DetalleMicroCapsulas} />
      <Route path={`${props.match.path}/categoria-noticias`} component={CategoriaMicroCapsulas} />
      <Route path={`${props.match.path}/admin`} component={MicroCapsulas} />
      <Route path={`${props.match.path}/url-youtube`} component={URLYoutube} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
