import { useContext, useState } from 'react'
import { Row, Col, Form, Button, FormControl, InputGroup, Alert } from 'react-bootstrap-v5'
import { UserContext } from '../../utility/context/userContext'
import { ContentContext } from './context'
import * as Icon from 'react-feather'

import { LoadingContext } from '../../utility/Loading/provider'
import { useForm } from "react-hook-form";
import { PostRoute } from '../../services/Private'
import { toast, Bounce } from 'react-toastify'

const Formulario = () => {

    const { userAuth } = useContext(UserContext),
        { oneData } = useContext(ContentContext),
        { setShowLoad } = useContext(LoadingContext),
        { register, handleSubmit, formState: { errors }, reset, watch } = useForm({
            mode: 'onTouched',
            reValidateMode: 'onChange'
        });
    const pass1 = watch('passwordDos');
    const [Visualizar, setVisualizar] = useState<any>('password'),
        Mensaje = `
        La contraseña debe de tener ocho caracteres como mínimo, debe incluir
        letras mayúsculas,
        letras minúsculas,
        números y
        cualquiera de los siguientes caracteres: $, @, !, %, *, ?, &, _, +`,
        onSubmit = async (data: any) => {

            if (data.passwordTres === data.passwordDos) {
                setShowLoad(true);
                const response = await PostRoute(`usuario/cambiarpass`, {
                    id: oneData.id,
                    password0: data.passwordUno,
                    password: data.passwordTres
                });
                toast.success(response.message, { position: 'bottom-right', transition: Bounce });
                reset()
                setVisualizar('password')
                setShowLoad(false);
            } else {
                toast.error('Contraseña no coincide', { position: 'bottom-right', transition: Bounce });
            }
        }


    const validarContrasena = (contrasena: string) => {
        let mensajeError = ""; // Variable para almacenar el mensaje de error

        // Verificar si la contraseña cumple con los criterios
        if (contrasena.length < 8) {
            mensajeError += "La contraseña debe tener al menos 8 caracteres. ";
        }

        if (!/[A-Z]/.test(contrasena)) {
            mensajeError += "La contraseña debe incluir al menos una letra mayúscula. ";
        }

        if (!/[a-z]/.test(contrasena)) {
            mensajeError += "La contraseña debe incluir al menos una letra minúscula. ";
        }

        if (!/[0-9]/.test(contrasena)) {
            mensajeError += "La contraseña debe incluir al menos un número. ";
        }

        if (!/[$@!%*?&_+]/.test(contrasena)) {
            mensajeError += "La contraseña debe incluir al menos uno de los siguientes caracteres: $, @, !, %, *, ?, &, _, +. ";
        }

        // Si hay algún criterio que no se cumple, devolver el mensaje de error
        if (mensajeError !== "") {
            return mensajeError;
        }

        // Si cumple con los criterios, devolver verdadero
        return true;
    }

    const comparePass = (value: string) => {
        if (value === pass1) {
            return true;
        }
        return 'las contraseñas no coinciden';
    };

    return (
        <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_create_account_stepper'
        >
            <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                <div className='px-6 px-lg-10 px-xxl-15 py-15'>
                    <div className='stepper-nav'>


                        <div className="symbol symbol-circle d-flex justify-content-center my-10">
                            {oneData &&
                                <div className="symbol-label w-125px h-125px"
                                    style={{
                                        backgroundImage: `url('${userAuth && userAuth.foto}')`
                                    }}>
                                </div>
                            }
                        </div>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-person-fill me-5 text-dark fs-3"></i> {userAuth && userAuth.nombreCompleto}
                                </Form.Group>

                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-person-circle me-5 text-dark fs-3"></i> {userAuth && userAuth.username}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-telephone-fill me-5 text-dark fs-3"></i> {oneData?.telefono}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-envelope me-5 text-dark fs-3"></i> {oneData?.email}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-person-badge-fill me-5 text-dark fs-3"></i> {oneData?.dpi}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="bi bi-person-badge me-5 text-dark fs-3"></i> {oneData?.nit}
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <i className="fa fa-venus-mars me-5 text-dark fs-3"></i>{oneData?.genero}
                                </Form.Group>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>

            <div className='d-flex flex-row-fluid flex-center py-10 bg-white rounded'>
                <div className='card-body py-15 w-100 mw-xl-700px px-15 fv-plugins-bootstrap5 fv-plugins-framework'>
                    <Row>
                        <Col>
                            <div className="fw-bolder py-2 h3">{"Actualizar Contraseña"}</div>

                            <Alert variant="warning">
                                <small>
                                    {Mensaje}
                                </small>
                            </Alert>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="mb-3" controlId="passwordUno">
                            <Form.Label>{"Contraseña Anterior"}</Form.Label>
                            <InputGroup className="mb-3">

                                <FormControl
                                    type={Visualizar}
                                    {...register('passwordUno',
                                        {
                                            required: "Este campo es requerido",
                                            minLength:
                                            {
                                                value: 8,
                                                message: 'Mínimo 8 carácteres!'
                                            }
                                        }
                                    )
                                    }
                                    isInvalid={!!errors.passwordUno}
                                />

                                <Button onClick={(Visualizar === 'password') ? () => setVisualizar('text') : () => setVisualizar('password')} variant="secondary">
                                    <Icon.Eye size={16} />
                                </Button>
                            </InputGroup><p className="text-danger fw-danger">{!!errors.passwordUno && errors.passwordUno.message}</p>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="passwordDos">
                            <Form.Label>{"Nueva Contraseña"}</Form.Label>
                            <Form.Control
                                type={Visualizar}
                                {...register('passwordDos',
                                    {
                                        required: "Este campo es requerido",
                                        minLength:
                                        {
                                            value: 8,
                                            message: 'Mínimo 8 carácteres!'
                                        },
                                        validate: validarContrasena
                                    }
                                )
                                }
                                isInvalid={!!errors.passwordDos} />
                            <p className="text-danger fw-danger">{!!errors.passwordDos && errors.passwordDos.message}</p>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="passwordTres">
                            <Form.Label>{"Repetir Contraseña"}</Form.Label>
                            <Form.Control
                                type={Visualizar}
                                {...register('passwordTres',
                                    {
                                        required: "Este campo es requerido",
                                        minLength:
                                        {
                                            value: 8,
                                            message: 'Mínimo 8 carácteres!'
                                        }, validate: comparePass
                                    }
                                )
                                }
                                isInvalid={!!errors.passwordTres} />
                            <p className="text-danger fw-danger">{!!errors.passwordTres && errors.passwordTres.message}</p>
                        </Form.Group>
                        <Button variant="success" size="sm" type="submit">
                            <Icon.Save size={16} /> {"Actualizar Contraseña"}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Formulario