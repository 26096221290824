export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function numeroALetras(numero) {
  const UNIDADES = [
    'cero',
    'uno',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
  ]

  const DECENAS = [
    '',
    '',
    'veinte',
    'treinta',
    'cuarenta',
    'cincuenta',
    'sesenta',
    'setenta',
    'ochenta',
    'noventa',
  ]

  const ESPECIALES = [
    'diez',
    'once',
    'doce',
    'trece',
    'catorce',
    'quince',
    'dieciséis',
    'diecisiete',
    'dieciocho',
    'diecinueve',
  ]

  const CENTENAS = [
    '',
    'ciento',
    'doscientos',
    'trescientos',
    'cuatrocientos',
    'quinientos',
    'seiscientos',
    'setecientos',
    'ochocientos',
    'novecientos',
  ]

  function convertirParteEntera(parteEntera) {
    if (parteEntera === 100) {
      return 'cien'
    }
    if (parteEntera === 0) {
      return UNIDADES[0]
    }

    if (parteEntera < 10) {
      return UNIDADES[parteEntera]
    }

    if (parteEntera < 20) {
      return ESPECIALES[parteEntera - 10]
    }

    if (parteEntera === 20) {
      return 'veinte';
    }

    if (parteEntera < 30) {
      if (parteEntera === 21) {
        return 'ventiuno';
      } else if (parteEntera === 22) {
        return 'veintidós';
      } else if (parteEntera === 23) {
        return 'veintitrés';
      } else {
        return 'veinti' + UNIDADES[parteEntera - 20];
      }
    }

    if (parteEntera < 100) {
      const decena = Math.floor(parteEntera / 10)
      const unidad = parteEntera % 10
      return DECENAS[decena] + (unidad > 0 ? ' y ' + UNIDADES[unidad] : '')
    }

    if (parteEntera < 1000) {
      const centena = Math.floor(parteEntera / 100)
      const resto = parteEntera % 100
      if (resto === 0) {
        return CENTENAS[centena]
      }
      return CENTENAS[centena] + ' ' + convertirParteEntera(resto)
    }

    if (parteEntera === 1000) {
      return 'mil'
    }

    if (parteEntera < 2000) {
      return 'mil ' + convertirParteEntera(parteEntera % 1000)
    }

    if (parteEntera < 1000000) {
      return (
        convertirParteEntera(Math.floor(parteEntera / 1000)) +
        (parteEntera % 1000 === 0 ? ' mil' : ' mil ' + convertirParteEntera(parteEntera % 1000))
      )
    }
  }

  function convertirParteDecimal(parteDecimal) {
    const decimales = parteDecimal.padEnd(2, '0')
    return convertirParteEntera(Number(decimales))
  }

  const partes = String(numero).split('.')
  const parteEntera = Number(partes[0])
  const parteDecimal = partes[1] || '0'

  if (parteEntera === 0 && parteDecimal === '0') {
    return 'cero'
  }

  let resultado = ''

  if (parteEntera > 0) {
    resultado += convertirParteEntera(parteEntera)
  }

  if (parteDecimal !== '0') {
    resultado += ' con ' + convertirParteDecimal(parteDecimal)
  }

  return resultado
}

export function formatDate3(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('-')
}
export function formatDate2(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('/')
}
export function formatDateHours(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    datetext = d.getHours() + ":" + d.getMinutes()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-') + ' ' + datetext
}

export function formatQuetzal(num) {
  return "Q." + (num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ab], { type: mimeString })

  return blob
}

export function stringToDate(_date, _format, _delimiter) {
  var formatLowerCase = _format?.toLowerCase()
  var formatItems = formatLowerCase?.split(_delimiter)
  var dateItems = _date?.split(_delimiter)
  var monthIndex = formatItems?.indexOf('mm')
  var dayIndex = formatItems?.indexOf('dd')
  var yearIndex = formatItems?.indexOf('yyyy')
  var month = parseInt(dateItems[monthIndex])
  month -= 1
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex])
  return formatedDate
}

export function formatDateLang(date, lang, delimiter) {
  const d = new Date(date)
  let fecha = null
  if (lang === 'en') {
    fecha = `${d.getFullYear()}-${`00${d.getMonth() + 1}`.slice(-2)}-${`00${d.getDate()}`.slice(
      -2
    )}`
  }
  if (lang === 'es') {
    fecha = `${`00${d.getDate()}`.slice(-2)}-${`00${d.getMonth() + 1}`.slice(
      -2
    )}-${d.getFullYear()}`
  }
  if (lang === 'es-en') {
    const f = date.split(delimiter)
    const fIngles = `${`${Number(f[2])}`}-${`00${Number(f[1])}`.slice(-2)}-${`00${Number(f[0]) + 1}`.slice(-2)}`
    console.log(fIngles, 'fecha')
    fecha = new Date(fIngles)
  }
  return fecha
}

const styleClass = (base) => ({
  ...base,
  border: 'solid 1px #fd397a !important',
  boxShadow: 'none',
  borderRadius: '5px',
})

const styleClass2 = (base) => ({
  ...base,
  border: 'solid 1px #fd397a !important',
  boxShadow: 'none',
  borderRadius: '5px',
  zIndex: 9999,
})

export const toDataURL = (url, callback) => {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const customStyles = {
  control: styleClass,
}
export const customStyles2 = {
  control: styleClass2,
}
export const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
export const mesesInicialMayus = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
export const validUrl = ['/dashboard', '/libros-formas/cooperativa/', '/libros-formas/catalogos/GestionFormatos', '/public/cooperativa'];

export const dataUrlToFile = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob();
  const file = new File([blob], fileName + '.pdf', { type: 'application/pdf' })
  return file;
}

export function returnMonth(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1)

  if (month.length < 2) month = '0' + month

  return month + ''
}

export function returnYear(date) {
  var d = new Date(date),
    year = d.getFullYear()

  return year + ''
}

export function quitarAcentos(cadena){
  if (typeof cadena !== 'string') {
      // manejar el caso en que cadena no es una cadena
      // por ejemplo, podrías devolver la cadena sin modificar
      return cadena;
  }

  const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
  return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, function(c){
      return acentos[c] || c;
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  toDataURL,
  formatDate,
  formatDate2,
  formatDate3,
  formatQuetzal,
  formatDateHours,
  formatDateLang,
  stringToDate,
  dataURItoBlob,
  customStyles,
  customStyles2,
  dataUrlToFile,
  quitarAcentos,
  meses,
  mesesInicialMayus,
  validUrl,
  numeroALetras
}
