/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const CounterAdmin = () => {
  

  return (
      <AsideMenuItemWithSub
        to='/eventos'
        title= {"Counter Eventos"}
        featherIcon={<i className="bi-clock-history fs-3"></i>}
      >
        
        <AsideMenuItem
          to='/eventos/admin'
          title={"Administrar Counter Eventos"}
          biIcon="bi bi-grid-1x2"
        />
      </AsideMenuItemWithSub>
  )
}

export default CounterAdmin