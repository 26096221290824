/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext } from 'react'
// import {Link} from 'react-router-dom'
import { UserContext } from '../../../../app/utility/context/userContext'
import { setAuthUser } from '../../../../app/utility/redux/actions/user';
import { useDispatch } from 'react-redux'

const HeaderUserMenu: FC = () => {

  const dispatch = useDispatch();

  const { userAuth } = useContext(UserContext)
  // const dispatch = useDispatch()
  const logout = () => {
    localStorage.removeItem('authUserI');
    dispatch(setAuthUser(null))
    window.location.href = "/auth";
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={userAuth && userAuth.foto} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              { (userAuth) && userAuth.nombreCompleto }
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              { (userAuth) && userAuth.username }
            </a>
          </div>
        </div>
      </div>
    
        {/* <div>
          <div className='separator my-2'></div>
            <div className='menu-item px-5'>
              <Link to={'/perfil'} className='menu-link px-5'>
              {"Mi Perfil"}

              </Link>
            </div>
        </div> */}


      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {"Cerrar Sesión"}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
