
import { Form ,Card, Button,Col,Row} from "react-bootstrap-v5"
import { Link } from 'react-router-dom'
import { PostRoute } from '../../services/Public'
// import { ToastContainer, toast, Bounce } from 'react-toastify';
import { toast } from 'react-toastify'
import { useForm, Controller } from "react-hook-form";
import {useEffect, useState } from 'react'
import { useParams} from "react-router-dom"
import * as Icon from 'react-feather'
import { Bounce } from 'react-toastify'

const Reset = () => {
    
    const {codigo}  = useParams<any>(),
    [Show, setShow] = useState(false),
    { formState: { errors }, control, handleSubmit } = useForm(),
    redirectLogin = () => {
        window.location.href = '/auth/login'
    },
    onSubmit = async (data) => {
        if (data.passwordTres === data.passwordDos) {
            const response = await PostRoute(`usuario/resetpass`, {
                usuario: data.usuario,
                codigo,
                password: data.passwordTres
            })
            toast.success(response.message, { position : 'bottom-right', transition: Bounce })
            redirectLogin()
        } else {
            toast.error('Contraseña no coincide', { position : 'bottom-right', transition: Bounce })
        }
    },
    tokenFail = async () => {
        
        const response = await PostRoute(`usuario/tknreset`, {codigo})
        if (response !== null && response !== 0 && response.value !== 2009) {
            setShow(true)
        }
    }
    
    useEffect(
        () => {
            async function fetchMyAPI() {
                await tokenFail()
            }
            
            fetchMyAPI()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [codigo]
    )

    
    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {Show ? 
            <Form className="form fv-plugins-bootstrap fv-plugins-framework"  onSubmit={handleSubmit(onSubmit)}>
                <Card.Title  className='fw-bold mb-1 text-center'>
                    Restablecer Contraseña
                </Card.Title>
                <Card.Text className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <p className='text text-center'>
                        Si ha solicitado un restablecimiento de contraseña complete los siguientes campos caso contrario inicie sesion con sus credenciales normalmente
                    </p>  
                </Card.Text>
                        <Row className="form-group fv-plugins-icon-container mb-10">
                            <Col  >
                                <Form.Group >
                                    <Form.Label ><Icon.Circle className="text-danger" size={16} /> Ingrese Usuario</Form.Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        name='usuario'
                                        render={({ field }) => (
                                            <Form.Control
                                            className='form-control form-control-solid h-auto py-5 px-5'
                                                    type='text'
                                                    isInvalid={!!errors.usuario}
                                                    {...field}
                                                />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                </Form.Group >
                            </Col>
                        </Row>

                        <Row className="form-group fv-plugins-icon-container mb-5">
                            <Col >
                                <Form.Group >
                                    <Form.Label><Icon.Circle className="text-danger" size={16} /> Nueva contraseña</Form.Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        name='passwordDos'
                                        render={({ field }) => (
                                            
                                                <Form.Control
                                                className='form-control form-control-solid h-auto py-5 px-5'
                                                type="password" 
                                                isInvalid={errors.passwordDos && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                },
                                                pattern: 
                                                    {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                                        message: "La contraseña no cumple con los requisitos"
                                                    },
                                                minLength: 
                                                    {
                                                        value: 8, 
                                                        message: 'Mínimo 8 carácteres!'
                                                    }
                                            }
                                        }
                                    />
                                    <p className="text-danger fw-danger">{!!errors.passwordDos && errors.passwordDos.message}</p>
                                    
                                </Form.Group >
                            </Col>
                        </Row>

                        <Row className="form-group fv-plugins-icon-container mb-5">
                            <Col>
                                <Form.Group >
                                    <Form.Label ><Icon.Circle className="text-danger" size={16} /> Repertir Contraseña</Form.Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        name='passwordTres'
                                        render={({ field }) => (
                                            <Form.Control
                                                className='form-control form-control-solid h-auto py-5 px-5'
                                                type="password"
                                                isInvalid={errors.passwordTres && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                },
                                                pattern: 
                                                    {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                                        message: "La contraseña no cumple con los requisitos"
                                                    },
                                                minLength: 
                                                    {
                                                        value: 8, 
                                                        message: 'Mínimo 8 carácteres!'
                                                    }
                                            }
                                        }
                                    />
                                    <p className="text-danger fw-danger">{!!errors.passwordTres && errors.passwordTres.message}</p>
                                </Form.Group >
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <div className='pt-2 text-center w-100'>
                                    <Link to="/auth/login">
                                        <Icon.ChevronLeft size={18}/> Atrás para iniciar sesión
                                    </Link>
                                </div>
                            </Col>
                            
                            <Col className="d-flex flex-wrap justify-content-center align-items-center" >
                                <Form.Group >
                                
                                    <Button type="submit" color="success">
                                        Confirmar <Icon.Save size={16} />
                                    </Button>
                                </Form.Group >
                            </Col>
                        </Row>
                    </Form>  
                     : "Tu enlace de restablecimiento de contraseña ha expirado"}
            
        </div>
    )
}

export default Reset