
import { Form ,Card, Button} from "react-bootstrap-v5"
import { Link } from 'react-router-dom'
import { PostRoute } from '../../services/Public'
// import { ToastContainer, toast, Bounce } from 'react-toastify';
import {Notification} from '../../utility/Notify/index'
import { useForm, Controller } from "react-hook-form";
// import { useState } from 'react'
// import clsx from 'clsx'
import * as Icon from 'react-feather'


const ForgotPassword = () => {
    
    const { control, handleSubmit, formState: { errors } } = useForm(),
        // eslint-disable-next-line 
        onSubmit = async (data: any) => {
            const response = await PostRoute(`usuario/reset/default/password`, 
            { email :data.email})
            
            Notification(response.message, 1)
        }

        

    return (
        <div>
            <Card.Title  className='fw-bold mb-1 text-center'>
                ¿Has olvidado tu contraseña?
            </Card.Title>
            <Card.Text className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña
            </Card.Text>
            <Form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-1'>
                <Form.Label className='form-label' >
                    Correo Electrónico
                </Form.Label>
                <Controller
                    defaultValue=''
                    control={control}
                    name='email'
                    render={({ field }) => (
                            <Form.Control
                                className='form-control form-control-solid h-auto py-5 px-5'
                                type='email'
                                isInvalid={!!errors.email}
                                placeholder='john@example.com' 
                                {...field}
                            />
                        )
                    }
                    rules={
                        { 
                            required: {
                                value: true,
                                message: 'Este campo es requerido'
                            } 
                        } 
                    }
                />
              </div>
              <Button color='primary' type='submit' block >
                Enviar
              </Button>
              <div className='pt-2 text-center w-100'>
                <Link to="/auth/login">
                    <Icon.ChevronLeft size={18}/> Atrás para iniciar sesión
                </Link>
              </div>
            </Form>
        </div>
    )
}

export default ForgotPassword