import { lazy } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const 
CIAR = lazy(() => import('../../../views/CIAR/index'))


const Index = (props: any) =>{
  return (
    <Switch>
      <Route path={`${props.match.path}/`} component={CIAR} />
      <Redirect to='/error/404' />
    </Switch>
  )
}

export default Index
