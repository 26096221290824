/* eslint-disable no-tabs */
import {createContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {toDataURL} from '../Globales/index'
import Blowfish from 'javascript-blowfish'
// import {setAuthUser} from '../../utility/redux/actions/user'
// import {useDispatch} from 'react-redux'

export const UserContext = createContext()

export const UserProvider = ({children}) => {
  // const dispatch = useDispatch()

  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY)
  const Roles = JSON.parse(process.env.REACT_APP_ROLES)
  const state = JSON.parse(process.env.REACT_APP_STATE)
  const appLog = JSON.parse(process.env.REACT_APP_LOG)
  const storage = localStorage.getItem('authUserI')
  const publicPath = ['/auth/login','/auth/forgot-password', '/auth/reset-password/', '/logout']
  const [userAuth, setUserAuth] = useState([])
  const [userRoles, setUseRoles] = useState([])
  const [base64] = useState([])
  const LogoPdf = toAbsoluteUrl(`/media/logos/${process.env.REACT_APP_LOGO_APP_REPORT}`)
  useEffect(() => {
    if (storage) {
      const response = JSON.parse(storage)
      const Decript = bf.decrypt(bf.base64Decode(response.data))
      if (Decript) {
        const data = JSON.parse(Decript.replace(/\0/g, ''))
        // dispatch(setAuthUser({estado: state[1], login: appLog[1]}))
        setUserAuth(data)
        setUseRoles(JSON.parse(data.roles))
      }
    } else {
      setUserAuth([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const url = window.location.pathname.split('/')

    if (!userAuth && url[1] !== 'auth') {
      localStorage.removeItem('authUserI')
      window.location.replace('/auth')
    }
  }, [userAuth])

  const setLogos = async () => {
    await toDataURL(LogoPdf, (Base) => base64.push({logoAmarillo: Base}))
  }

  useEffect(() => {
    setLogos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = {userAuth, Roles, userRoles, LogoPdf, base64, state, appLog, publicPath, storage}

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
