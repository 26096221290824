import { FC } from 'react'
import * as Icon from 'react-feather'
import { Form } from 'react-bootstrap-v5'

type FormData = {
    required: boolean
    text: string
    type?: string
    className?: string | ''
}

const CustomLabel: FC<FormData> = ({required, text, type, className}) => {
    return (
        <Form.Label className="fw-bolder w-100">
            <div className="d-flex">
                <div className="py-1 w-icon-circle flex-shrink-1 d-flex align-self-center align-items-center">
                    { required && <Icon.Circle size={10} className={`text-${type} text-${type}-fill`} /> }
                </div>
                <div className={`p-1 ps-2 pe-0 w-100 ${className}`}>
                    { text }
                </div>
            </div>
        </Form.Label>
    )
}

export default CustomLabel